
import { Component, Vue } from 'vue-property-decorator';
import { Scale } from '@/shared/components';
import { ExerciseDetails } from '@/models';
import { isExerciseOldType } from '@/helpers/exercise';

@Component({
  name: 'ExercisePreview',
  components: { Scale }
})
export default class ExercisePreview extends Vue {
  exercise: ExerciseDetails
  variantIndex = 0;
  isLoaded = false;
  currentVariantIndex = 0;
  isCriteriaTabVisible = false;
  isExerciseOld = false

  changeExerciseVariantNumber (index: number) {
    this.isCriteriaTabVisible = false;
    this.currentVariantIndex = index;
  }

  async mounted () {
    const exercise = await (this.$api.get(`exercises/${this.$route.params.id}/${this.$route.params.hash}`, { data: { excludeToken: true } }));
    this.exercise = exercise.data as ExerciseDetails;
    this.isLoaded = true;
    this.isExerciseOld = isExerciseOldType(this.exercise.answersType) as boolean;
  }
}
